<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <card class="pb-5">
            <template slot="header">
                <h4 class="card-title">{{type}} {{title}}</h4>
            </template>
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <base-input type="text" :label="$t('Nombre')" autocomplete="false"  v-model="name">
                            <template v-slot:textError v-if="$v.name.$error">
                                <span v-if="!$v.name.required" >{{$t('Campo_requerido')}}</span>
                            </template>
                        </base-input>
                    </div>
                </div> 
                 <div class="row">
                    <div class="col-md-6">
                        <base-input type="text" :label="$t('Email')" autocomplete="false"  v-model="email">
                            <template v-slot:textError  v-if="$v.email.$error">
                                <div  v-if="!$v.email.required" >{{$t('Campo_requerido')}}</div>
                                <div  v-if="!$v.email.emal" >{{$t('validation_email')}}</div>
                            </template>
                        </base-input>
                    </div>
                     <div class="col-md-6">
                        <base-input type="password" autocomplete="false" :label="$t('Password')"  v-model="password">
                            <template v-slot:textError v-if="$v.password.$error">
                                <span v-if="!$v.password.required" >{{$t('Campo_requerido')}}</span>
                            </template>
                        </base-input>
                    </div>
                </div>
                <div class="row">
                    <!--<div class="col-md-6">
                      <base-input type="text" :label="$t('Old Password')" :value="loadPasswordMd5(email)">
                        <template v-slot:textError v-if="$v.password.$error">
                          <span v-if="!$v.password.required" >{{$t('Campo_requerido')}}</span>
                        </template>
                      </base-input>
                    </div>-->
                    <div class="col-md-6">
                        <base-select :label="$t('Rol')" v-model="rol_id" :items="roles">
                            <template v-slot:textError v-if="$v.rol_id.$error">
                                <span v-if="!$v.rol_id.required" >{{$t('Campo_requerido')}}</span>
                            </template>
                        </base-select>
                    </div>
                </div> 
                <div class="d-flex justify-content-end ">
                    <button type="button" class="btn btn-dark btn-fill float-right py-2" @click="submit">
                        <span class="font-weight-bold px-3">
                        {{$t("Guardar")}}
                        </span>
                    </button>
                </div>
                <div v-if="error">
                    <span class="text-sm text-danger">{{ $t("error") }}</span>
                    <ul class="text-sm error-list" v-html="server_error"></ul>
                </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import { apiCallSingle } from '../../mixins'
    import { required, email} from 'vuelidate/lib/validators';
import BaseSelect from '../../components/Inputs/BaseSelect.vue';
    export default {
        mixins: [apiCallSingle],
        components: {
                BaseSelect
        
        },
        props : {
            id: String
        },
        data () {
            return {
                endpoint: "user",
                section : "admin/users",
                title : this.$t('Usuario'),
                name    : null,
                email   : null,
                password: null,
                rol_id  : null,
                roles   : []
            }
        },
        validations(){
            return {
                name : {
                    required
                },
                email : {
                    required,
                    email
                },
                password : {
                    required
                },
                rol_id : {
                    required
                }             
            }
        },
        created(){
            this.loadRoles();
        },
        methods : {
            loadPasswordMd5(data){
              return data;
              /*this.$axios ("/user?where-email=" + data)
                  .then ((response) => {
                    console.log("----------------");
                    console.log(response.data[0].password);
                  })*/
            },
            loadData(){
                this.name   = this.data.name;
                this.email  = this.data.email;
                this.rol_id = this.data.rol_id;
            },
            getProperties(){
                return {
                    name    : this.name,
                    email   : this.email,
                    password: this.password,
                    rol_id  : this.rol_id
                }
            },
            loadRoles(){
                this.$axios("/roles")
                    .then((response) => {
                        this.roles = response.data;
                    })
            }
        }
  }
</script>
<style>
</style>